import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";

import Padding from './../../frontend/layout/Padding';
import Icon from "./../../common/components/Icon.js";

import Logo from './../../common/components/Logo';
// import Button from './../../common/components/Button';

import {BREAKPOINT_SM} from './../../config';

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  @media (max-width: ${BREAKPOINT_SM}px){
    min-height: 60px;
  }
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  background-color: #2086F2;
  z-index: 99;
`;

const Content = styled.div`
  height: 100px;
  @media (max-width: ${BREAKPOINT_SM}px){
    height: 80px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;

const MenuCnt = styled.div`
  @media (max-width: 991px){
    position: fixed;
    top: 80px;
    left: ${props => props.isOpen ? "0px" : "-100vw"};
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: #2086F2;
    transition: all 0.25s ease-in-out;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const MobiIconsCnt = styled.div`
  z-index: 5;
  line-height: 0px;
  display: flex;
  color: #ffffff;
  @media (min-width: 992px){
    display: none;
  }
`;




const Nav = styled.div`
  overflow: hidden;

  white-space: nowrap;
  display: flex;
  flex-direction: row;
  list-style: none;

  @media (min-width: 992px){
    align-items: center;
  }

  @media (max-width: 991px){

    flex-direction: column;
    text-align: center;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075) inset;
    max-height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const Link = styled(NavLink)`
  font-size: 20px;
  font-family: "Noto Serif", serif;
  letter-spacing: 2.5px;
  float: left;
  display: block;
  margin: 17px 10px;
  text-decoration: none !important;
  
  &:hover, &:hover *{
    background-color: #ffffff;
    color: #2086F2;
    text-decoration: none !important;
  }
`;

const Item = styled.span`
  
  padding: 0px 5px;
  font-weight: 500;
  background-color: ${props => props.active ? "#ffffff" : "transparent"};
  color: ${props => props.active ? "#2086F2" : "#ffffff"};
  text-decoration: none !important;
`;


const Brand = styled(NavLink)`
  

`;
// const BrandName = styled.div`
//   line-height: 30px;
// `;
const MobiToggle = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Topbar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapper>
      <Padding right left>
        <Content>
          <Brand to={"/"}><Logo height={window.screen.width > BREAKPOINT_SM ? "70px" : "50px"} /></Brand>
          <MenuCnt isOpen={isOpen}>
            <Nav>
              <Link exact="true" to={"/"}>
                {({ isActive }) => (<Item active={isActive}>Home</Item>)}
              </Link>
              <Link exact="true" to={"/dove-siamo"}>
                {({ isActive }) => (<Item active={isActive}>Dove Siamo</Item>)}
              </Link>
              <Link exact="true" to={"/cosa-offriamo"}>
                {({ isActive }) => (<Item active={isActive}>Cosa Offriamo</Item>)}
              </Link>
              <Link exact="true" to={"/la-struttura"}>
                {({ isActive }) => (<Item active={isActive}>La Struttura</Item>)}
              </Link>
              <Link exact="true" to={"/contatti"}>
                {({ isActive }) => (<Item active={isActive}>Contatti</Item>)}
              </Link>
            </Nav>
          </MenuCnt>
          <MobiIconsCnt>
            <MobiToggle onClick={() => setOpen(!isOpen)}>
              <Icon name="menu" size="24px" />
            </MobiToggle>
          </MobiIconsCnt>
        </Content>
      </Padding>
    </Wrapper>
    
  )
}

export default Topbar;

