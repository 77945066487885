import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';



const LaScuola = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Turismo Equestre"}
      />
      <Header 
        text={"Turismo Equestre"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo-turismoequestre"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Turismo Equestre</Typo>
          <Typo type="p">
            Non c’è niente di più bello che fare una passeggiata a cavallo in mezzo alla natura, circondati da vastissimi campi verdi e canali percorribili liberamente a cavallo. L'attività è adatta sia a principianti che esperti. Sono previste passeggiate organizzate, su accompagnamento di personale autorizzato e competente. La zona del Parco del Mincio in cui siamo ubicati offre un'ampia varietà di paesaggi e la possibilità di godersi il tramonto.<br/>
            “Per i più’ avventurosi e intraprendenti vi &egrave; poi la possibilità di prendere parte a raduni equestri regionali e nazionali”
          </Typo>          
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaScuola
