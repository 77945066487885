export const firebaseConfig = {
  apiKey: "AIzaSyBtMnLhAV7Kymn2DnPCzl4vrDmD-Fo1luU",
  authDomain: "lalunanuovassd.firebaseapp.com",
  projectId: "lalunanuovassd",
  storageBucket: "lalunanuovassd.firebasestorage.app",
  messagingSenderId: "75895356972",
  appId: "1:75895356972:web:eca83b662c3d3f9ad59b89",
  measurementId: "G-7G46GH9TTR"
};

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;
export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";
export const PAGESIZE = 20;