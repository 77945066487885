import React from "react";
import styled from 'styled-components';

import {Link} from 'react-router-dom';

const Wrapper  = styled(Link)`
  font-family: "Comic Neue", cursive;
  display: ${props => props.display};
  width: ${props => props.width};
  ${props => props.display === "inline" ? "z-index: 3;" : null}
  vertical-align: middle;
  border: 2px solid black;
  color: #000;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  text-align: center;
  background-clip: border-box;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  box-sizing: border-box;
  letter-spacing: inherit;
  font-size: inherit;
  cursor: ${props => props.disabled ? "default" : "pointer"};
  pointer-events: ${props => props.disabled ? "none" : "auto"};
  opacity: ${props => props.disabled ? "0.5" : "1"};
  

  &, & * {
    box-sizing: border-box;
  }

  &:active, &:active *,
  &:focus, &:focus *,
  &:hover, &:hover *{
    box-shadow: none;
    outline: none;
    text-decoration: none;
  }

  ${props => props.size === "big" ? `
    height: 42px;
    min-width: 120px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;
    & > div > div:first-child{
      padding: 0px 12px 0px;
    }
  ` : `
    height: 34px;
    
    min-width: 100px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    & > div > div:first-child{
      padding: 0px 10px 0px;
    }
  `};

  ${props => props.variant === "default" ? `
    //todo
  ` : props => props.variant === "default-o" ? `
    color: #2086F2;
    background-color: transparent;    
    &, & > div > div{
      border-color: #2086F2;
    }
    &:hover{
      color: #ffffff;
      background-color: #2086F2;
      border-color: #2086F2;
      &, & > div > div{
        border-color: #2086F2;
      }
    }
  ` : props => props.variant === "light-o" ? `
    color: #ffffff;
    background-color: transparent;    
    &, & > div > div{
      border-color: #ffffff;
    }
    &:hover{
      color: #2086F2;
      background-color: #ffffff;
      border-color: #ffffff;
      &, & > div > div{
        border-color: #ffffff;
      }
    }
  ` : `

  `};
`;


const SubWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;
const TextWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const Cta = ({
    variant = "default",
    size = "medium",
    width = "auto",
    display = "inline-block",
    icon = null,
    text = null,
    disabled = false,
    link
  }) => {
  return (
    <Wrapper
      variant={variant}
      size={size}
      display={display}
      width={width}
      disabled={disabled}
      to={link}
    >
      <SubWrapper>
        {text ?
          <TextWrapper>
            {text}
          </TextWrapper>
        : null }
      </SubWrapper>
    </Wrapper>
  )
}

export default Cta;
