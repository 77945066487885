import {useState, useCallback, useEffect} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
// import { getAnalytics } from "firebase/analytics";

//Frontend
import Home from "./frontend/pages/Home";
import DoveSiamo from "./frontend/pages/DoveSiamo";
import CosaOffriamo from "./frontend/pages/CosaOffriamo";
import CosaOffriamoScuola from "./frontend/pages/CosaOffriamoScuola";
import CosaOffriamoAgonismo from "./frontend/pages/CosaOffriamoAgonismo";
import CosaOffriamoPasseggiate from "./frontend/pages/CosaOffriamoPasseggiate";
import CosaOffriamoDidattica from "./frontend/pages/CosaOffriamoDidattica";
import CosaOffriamoPensione from "./frontend/pages/CosaOffriamoPensione";
import LaStruttura from "./frontend/pages/LaStruttura";
import Contatti from "./frontend/pages/Contatti";


//404
import NotFound from "./frontend/pages/NotFound";

import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';


const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  const [layout, setLayout] = useState(null);


  const handleResize = useCallback(() => {
    setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  return (
    <Routes>

      {/*Frontend*/}
      <Route exact path='/' element={<Home layout={layout} />} />
      <Route exact path='/dove-siamo' element={<DoveSiamo layout={layout} />} />
      <Route exact path='/cosa-offriamo' element={<CosaOffriamo layout={layout} />} />
      <Route exact path='/cosa-offriamo/scuola-equitazione-mantova/' element={<CosaOffriamoScuola layout={layout} />} />
      <Route exact path='/cosa-offriamo/agonismo/' element={<CosaOffriamoAgonismo layout={layout} />} />
      <Route exact path='/cosa-offriamo/passeggiate-a-cavallo-mantova/' element={<CosaOffriamoPasseggiate layout={layout} />} />
      <Route exact path='/cosa-offriamo/attivita-didattiche/' element={<CosaOffriamoDidattica layout={layout} />} />
      <Route exact path='/cosa-offriamo/pensione-cavalli-mantova' element={<CosaOffriamoPensione layout={layout} />} />
      <Route exact path='/la-struttura' element={<LaStruttura layout={layout} />} />
      <Route exact path='/contatti' element={<Contatti layout={layout} />} />
      
      {/*301*/}
      <Route exact path='/dove-siamo-2' element={<Navigate to={"/dove-siamo"} />} />
      <Route exact path='/scuola-equitazione-mantova' element={<Navigate to={"/cosa-offriamo/scuola-equitazione-mantova"} />} />
      <Route exact path='/agonismo' element={<Navigate to={"/cosa-offriamo/agonismo"} />} />
      <Route exact path='/passeggiate-a-cavallo-mantova' element={<Navigate to={"/cosa-offriamo/passeggiate-a-cavallo-mantova"} />} />
      <Route exact path='/attivita-didattiche' element={<Navigate to={"/cosa-offriamo/attivita-didattiche"} />} />
      <Route exact path='/pensione-cavalli-mantova' element={<Navigate to={"/cosa-offriamo/pensione-cavalli-mantova"} />} />

      {/*404*/}
      <Route path='*' element={<NotFound layout={layout} />} />
    </Routes>
  );
}

export default App;
