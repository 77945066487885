import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';



const LaScuola = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Pensione Cavalli"}
      />
      <Header 
        text={"Pensione Cavalli"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo-pensionecavalli"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Pensione Cavalli</Typo>
          <Typo type="h3">Scuderia</Typo>
          <Typo type="p">
            Il centro offre la possibilità di scuderizzazione in box, provvisto di un paddock esterno privato. La lettiera è disponibile sia in truciolo che in paglia.
          </Typo>
          <Typo type="h3">Paddock</Typo>
          <Typo type="p">
            Il centro offre la possibilità di collocare i cavalli in ampi paddock esterni, singoli o multipli, in cui i cavalli vivono H24. Tutti i paddock sono provvisti di capannina, acqua fresca e fieno, e sono soggetti a cure e controlli giornalieri garantiti.
          </Typo>   
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaScuola
