import React from 'react'
import styled from 'styled-components'


const Heading1 = styled.h1`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 46px;
  margin-bottom: 46px;
  letter-spacing: 3px;
`;
const Heading2 = styled.h2`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 36px;
  margin-bottom: 36px;
  letter-spacing: 3px;
`;
const Heading3 = styled.h3`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 32px;
  margin-bottom: 32px;
  letter-spacing: 3px;
`;
const Heading4 = styled.h4`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 26px;
  margin-bottom: 26px;
`;
const Heading5 = styled.h5`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 20px;
  margin-bottom: 20px;
`;
const Heading6 = styled.h6`
  font-family: "Noto Serif", serif;
  font-weight: 500;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
  font-size: 16px;
  margin-bottom: 16px;
`;
const Paragraph = styled.p`
  ffont-family: "Comic Neue", cursive;
  text-align: ${props => props.align !== undefined ? props.align : "left"};
`;

const Typo = ({ type, align, children }) => {
  // console.log(type)
  switch (type) {
    case "h1":
      return (
        <Heading1 align={align}>
          {children}
        </Heading1>
      )
      case "h2":
        return (
          <Heading2 align={align}>
            {children}
          </Heading2>
        )
      case "h3":
        return (
          <Heading3 align={align}>
            {children}
          </Heading3>
        )
      case "h4":
        return (
          <Heading4 align={align}>
            {children}
          </Heading4>
        )
      case "h5":
        return (
          <Heading5 align={align}>
            {children}
          </Heading5>
        )
      case "h6":
        return (
          <Heading6 align={align}>
            {children}
          </Heading6>
        )
      case "p":
        return (
          <Paragraph align={align}>
            {children}
          </Paragraph>
        )
    default:
      return (
        <>
          {children}
        </>
      )
  }
}


export default Typo
