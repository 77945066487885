import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';



const LaScuola = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Attività Didattiche"}
      />
      <Header 
        text={"Attività Didattiche"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo-attivitadidattiche"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Attività Didattiche</Typo>
          <Typo type="h3">Settimane Verdi</Typo>
          <Typo type="p">
          Durante il periodo estivo si organizzano le settimane verdi. Dal lunedì al venerdì, grazie alla partecipazione di personale preparato, i bambini imparano a stare a contatto con i cavalli e con la natura, intraprendendo anche svariate attività di laboratorio e ricreative quali la pittura, il disegno, la cucina, ecc., non dimenticandosi che per avere una certa armonia è altresì necessaria l’osservazione di alcune piccole regole. I bambini vengono così accompagnati in un piccolo viaggio verso la scoperta del mondo equestre attraverso l’intraprendenza e il divertimento, svolgendo attività di maneggio quotidiane.
          </Typo>
          <Typo type="h3">Battesimo della Sella</Typo>
          <Typo type="p">
            Uno dei momenti più belli per qualsiasi bambino è indubbiamente la prima volta che si sale a cavallo. L’emozione che si prova a stare lassù non si scorda mai. Da un semplice e piccolo giro a cavallo può nascere una grande passione e un grande amore verso questi animali. Organizziamo questo tipo di eventi che fungono da battesimo della sella a tutti gli effetti, coordinati da personale esperto e qualificato.
          </Typo>
          <Typo type="h3">Feste di Compleanno</Typo>
          <Typo type="p">
            Non è necessario partecipare ai nostri corsi di equitazione per poter accedere al nostro maneggio. <br/>
            Non sai dove festeggiare il tuo compleanno o quello del tuo bambino? Ci pensiamo noi! Contattaci per ottenere maggiori informazioni circa la modalità, durata e le opzioni disponibili. L'attività è svolgibile durante tutto il periodo dell'anno. Vi faremo vivere un'esperienza unica passando una giornata in serenità tra i campi verdi e la natura!
          </Typo>              
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaScuola
