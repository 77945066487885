import React from 'react';
import styled from 'styled-components';

const Wrapper  = styled.div`
  position: relative;
  width: ${props => props.width !== undefined ? props.width : "auto"};
  height: ${props => props.height !== undefined ? props.height : "auto"};
`;
const Image  = styled.img`
  ${props => (props.width !== undefined && props.height !== undefined) ? `
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  ` : ((props.width !== undefined) ? `
    width: ${props.width};
  ` : `
    height: ${props.height};
  `)}
`;

const Logo = ({width, height, inverse}) => {
  return (
    <>
      <Wrapper width={width} height={height}>
        <Image width={width} height={height} src={process.env.PUBLIC_URL + "/logo" + (inverse ? "" : "") + ".jpg"} alt="Logo La Luna Nuova" />
      </Wrapper>
    </>
    
  )
}

export default Logo;
