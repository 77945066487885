import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';
import Cta from '../../common/components/Cta';



const CosaOffriamo = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Cosa Offriamo"}
      />
      <Header 
        text={"Cosa Offriamo"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-lascuola.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">La scuola</Typo>
          <Typo type="p">
            L'attività principale del nostro centro è costituita dalla scuola di equitazione, che dispone di numerosi cavalli e anche di pony. Pratichiamo sia la monta inglese che la monta western. Durante tutto l'anno sono attivi corsi di avviamento per principianti e di...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/scuola-equitazione-mantova"
            icon="arrow_forward"
          />
        </Padding>
      </Section>
      
      <Section 
        variant="blue" 
        imageSx={"/web/sections/cosaoffriamo-agonismo.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Agonismo</Typo>
          <Typo type="p">
            Monta da Lavoro Tradizionale, Gimkana Western ed Endurance.
          </Typo>     
          <Cta 
            size="big"
            variant="light-o"
            text="Scopri di più"
            link="/cosa-offriamo/agonismo"
            icon="arrow_forward"
          />       
        </Padding>
      </Section>
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-turismoequestre.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Turismo Equestre</Typo>
          <Typo type="p">
            Non c’è niente di più bello che fare una passeggiata a cavallo in mezzo alla natura, circondati da vastissimi campi verdi e canali percorribili liberamente a cavallo. L'attività è adatta sia a principianti che esperti. Sono previste passeggiate organizzate, su accompagnamento di personale autorizzato...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/passeggiate-a-cavallo-mantova"
            icon="arrow_forward"
          />         
        </Padding>
      </Section>
      
      <Section 
        variant="blue" 
        imageSx={"/web/sections/cosaoffriamo-attivitadidattiche.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Attività Didattiche</Typo>
          <Typo type="p">
            Settimane Verdi, Battesimi della Sella e Feste di Compleanno!
          </Typo>       
          <Cta 
            size="big"
            variant="light-o"
            text="Scopri di più"
            link="/cosa-offriamo/attivita-didattiche"
            icon="arrow_forward"
          />          
        </Padding>
      </Section>
      
      <Section 
        variant="white" 
        imageDx={"/web/sections/cosaoffriamo-pensionecavalli.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Pensione Cavalli</Typo>
          <Typo type="p">
            Scuderia: il centro offre la possibilità di scuderizzazione in box, provvisto di un paddock esterno privato. La lettiera è disponibile sia in truciolo che in paglia. Paddock Esterno: il centro offre la possibilità di collocare i cavalli in ampi paddock esterni. Tutti...
          </Typo>    
          <Cta 
            size="big"
            variant="default-o"
            text="Scopri di più"
            link="/cosa-offriamo/pensione-cavalli-mantova"
            icon="arrow_forward"
          />             
        </Padding>
      </Section>
    </Layout>
  )
}

export default CosaOffriamo
