import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
// import Row from './../../common/components/Row';
// import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
// import Icon from "./../../common/components/Icon";


const LaStruttura = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"La Struttura"}
      />
      <Header 
        text={"La Struttura"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="lastruttura"
        alt="Emotional image"
      />


      <Section 
        variant="white" 
        imageDx={"/web/sections/lastruttura-lastruttura.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">La Struttura</Typo>
          <Typo type="p">
            <ul>
              <li>Scuderia (x12 box)</li>
              <li>Paddock: vari (di diverse dimensioni)</li>
              <li>x1 campo in erba: 30 mt X 70 mt (illuminato e irrigato)</li>
              <li>x1 campo in sabbia: 20 mt X 40 mt (illuminato e irrigato)</li>
              <li>x1 campo in sabbia: 50 mt X 80 mt (illuminato e irrigato)</li>
              <li>x1 tondino: 15 mt</li>
              <li>x2 postazioni lavaggio: 1 interna alla scuderia più 1 esterna (provviste di acqua calda)</li>
              <li>Solarium</li>
              <li>Selleria</li>
              <li>Club House</li>
            </ul>
          </Typo>  
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaStruttura;
