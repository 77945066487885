import React, {useEffect} from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

import Topbar from './Topbar';
import Footer from './Footer';

import {BREAKPOINT_SM } from '../../config';

const Wrapper = styled.div`
  margin-top: 100px;
  @media (max-width: ${BREAKPOINT_SM}px){
    margin-top: 80px;
  }
  width: 100vw;
  overflow-x: hidden;
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = ({ layout, children }) => {
    return (
    <Wrapper>
      <ScrollToTop />
      <Topbar layout={layout} />
      {children}
      <Footer />
    </Wrapper>
  )
}

export default Layout
