import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';



const LaScuola = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"Agonismo"}
      />
      <Header 
        text={"Agonismo"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo-agonismo"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">Agonismo</Typo>
          <Typo type="h3">Monta da Lavoro Tradizionale</Typo>
          <Typo type="p">
            &Egrave; una disciplina composta da 4 prove:
            <ul>
              <li>Addestramento Classico - una ripresa da svolgere in un rettangolo di lavoro, disegnando a cavallo determinate figure di maneggio con regolarità e precisione.</li>
              <li>Attitudine - un percorso con vari ostacoli in sequenza da eseguire in un rettangolo di lavoro. Non è una prova a tempo, bensì viene valutata l'attitudine allo svolgimento dell'ostacolo stesso, la posizione e l'avvicinamento.</li>
              <li>Gimkana - un percorso con i medesimi ostacoli di cui sopra, ma da svolgersi in velocità cercando di fare meno errori possibili, nel minor tempo possibile.</li>
              <li>Sbrancamento - consiste nel riuscire ad isolare un Vitello rispetto al resto della mandria, e tenerlo separato dalla mandria stessa per almeno 10 secondi.</li>
            </ul>
          </Typo>
          <Typo type="h3">Gimkana Western</Typo>
          <Typo type="p">
            Il cavaliere dovrà eseguire un percorso e superarne i relativi ostacoli, che riproducono situazioni di difficoltà che ogni cavaliere potrebbe incontrare durante una passeggiata in campagna, cercando di concludere il percorso in maniera pulita, nel minor tempo possibile. Questa disciplina si svolge in monta Western.
          </Typo>
          <Typo type="h3">Endurance</Typo>
          <Typo type="p">
            Si tratta di una specialità tra gli sport equestri che consiste nel percorrere distanze più o meno lunghe in campagna, su terreni di diversa natura. &Egrave; da intendersi come una prova di resistenza, da concludere in un tempo prestabilito oppure nel minor tempo possibile a seconda dei casi, facendo particolare attenzione alla salute e al benessere del cavallo che è elemento essenziale nell'attribuzione del punteggio finale.
          </Typo>          
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaScuola
