import React, {useEffect} from 'react';
// import styled from 'styled-components';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';



const LaScuola = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={"La Scuola"}
      />
      <Header 
        text={"La Scuola"}
        lowertext={"La Luna Nuova Centro Equestre"}
        page="cosaoffriamo-lascuola"
        alt="Emotional image"
      />
      
      <Section 
        variant="white" 
      >
        <Padding top right bottom left>
          <Typo type="h2" align="center">La scuola</Typo>
          <Typo type="p">
          L' attività principale del nostro centro è costituita dalla scuola di equitazione, che dispone di numerosi cavalli e anche di pony. Pratichiamo sia la monta inglese che la monta western.<br/>
          Durante tutto l'anno sono attivi corsi di avviamento per principianti e di perfezionamento e preparazione agonistica a vari livelli, anche per coloro che non sono principianti.<br/>
          &Egrave; previsto l'avvicinamento al mondo equestre per bambini a partire dai 3 anni che, imparando a rapportarsi con il cavallo (o con il pony), prendono consapevolezza dell’importanza che vi e’ nell’approccio verso l’animale giungendo poi sino alla messa in sella, accompagnati da personale qualificato.<br/>
          Per ogni cavaliere/amazzone sarà necessario tesserarsi presso un ente convenzionato con la nostra società.<br/>
          Sarà possibile scegliere se eseguire attività individuali oppure beneficiare di un pacchetto/carnet di attività.
          </Typo>          
        </Padding>
      </Section>
    </Layout>
  )
}

export default LaScuola
