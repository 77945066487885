import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Meta = ({title = null, description = null, image = null}) => {
  const location = useLocation();
  return (
    <Helmet>
      <title>{title ? title + " | La Luna Nuova" : "La Luna Nuova"}</title>
      <meta property="og:title" content={title ? title + " | La Luna Nuova" : "La Luna Nuova"} />
      {description ? 
        <meta name="description" content={description} />
      : 
        <meta name="description" content={"Centro equestre a Mantova"} />
      }
      {description ? 
        <meta property="og:description" content={description} />
      : 
        <meta property="og:description" content={"Centro equestre a Mantova"} />
      }
      {image ? 
        <meta property="og:image" content={image} />
      : 
        <meta property="og:image" content="https://www.lalunanuovamn.it/logo.jpg" />
      }
      <meta property="og:type" content="website" />
      <meta property="og:url" content={"https://www.lalunanuovamn.it"+location.pathname} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
    </Helmet>
  )
}

export default Meta;