import React, {useEffect} from 'react';
import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';

const Map = styled.div`
  margin-bottom: -6px;
`;

const Contatti = ({layout}) => {
  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  let iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53562.1324724449!2d10.845239032844637!3d45.12607589569508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f7faaec87b0d1%3A0x6568b11291ee813!2sLA%20LUNA%20NUOVA%20Mantova%20Centro%20Equestre!5e0!3m2!1sit!2sit!4v1741605710075!5m2!1sit!2sit" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';
  return (
    <Layout layout={layout}>
      <Meta
        title={"Contatti"}
      />

      <Map dangerouslySetInnerHTML={{__html: iframe}} />
      {/* <Section
        variant="white" 
        imageDx={"/web/sections/contatti-contatti.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h1">Contatti</Typo>
          <Typo type="p">xxx</Typo>
        </Padding>
     </Section> */}
     <Section variant="white">
        <Padding top right bottom left>
          <Row>
           <Col widthmd={6}>
              <Typo type="h3" align="center">
                Indirizzo
              </Typo>
              <Typo type="p" align="center">
                La Luna Nuova SSD<br/>
                Strada Luigi Cadorna, 9<br/>
                46100 Mantova (MN) 
              </Typo>
            </Col>
            <Col widthmd={6}>
              <Typo type="h3" align="center">
                Contatti
              </Typo>
              <Typo type="p" align="center">
                <a href="tel:+393473974344" target="_blank" rel="noreferrer">Marco: +39 3401737345</a><br/>
                <a href="mailto:info@lalunanuovamn.it" target="_blank" rel="noreferrer">Email: info@lalunanuovamn.it</a><br/>
              </Typo>
            </Col>
          </Row>
        </Padding>
      </Section>
    </Layout>
  )
}

export default Contatti
