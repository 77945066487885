import React from 'react'
import styled from 'styled-components'

import Row from './../../common/components/Row';
import Col from './../../common/components/Col';

import Logo from './../../common/components/Logo'
import Typo from './../../common/components/Typo'
import Section from './../../frontend/layout/Section'
import Padding from './../../frontend/layout/Padding'
import Socials from './Socials'

const Wrapper = styled.div`
`;

const Footer = () => {
  return (
    <Wrapper>
      <Section variant="blue">
        <Padding top right left>
          <Row>
            <Col widthmd={3}>
              <Logo width="60%" inverse /><br />
            </Col>
            <Col widthmd={6}>
              <Typo type="p">
                <b>Sede legale</b><br/>
                La Luna Nuova SSD<br/>
                Strada Luigi Cadorna, 9<br/>
                46100 Mantova (MN) <br/><br/>
                <b>P.IVA</b><br/>
                 93066330205
              </Typo>
            </Col>
            <Col widthmd={3}>
              <Socials inverse /><br/>
              {/* <Typo type="p">
                <b>Link utili</b><br/>
                <a target="_blank" rel="noreferrer" href="https://www.assofacile.it/calendario/lalunanuovamn">Consulta il calendario Eventi</a><br/>
                <a target="_blank" rel="noreferrer" href="https://www.assofacile.it/calendario/lalunanuovamn/iscriviti">Iscriviti alla Società</a><br/>
                <a target="_blank" rel="noreferrer" href="https://www.assofacile.it/calendario/lalunanuovamn">Area riservata tesserati</a>
              </Typo> */}
            </Col>
          </Row><br/><br/>
          <center>
            ©{(new Date().getFullYear())} La Luna Nuova SSD Centro Equestre
            <br/><br/>
          </center>
        </Padding>
      </Section>     
    </Wrapper>
  )
}

export default Footer
